<template>
  <div>
    <h2>Contact</h2>
    <router-link to="/">
      Cancel
    </router-link>
    <a>
      Submit
    </a>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: {}
};
</script>
